:root {
	// Header
	--hd-height: 60px; // Mobile Height
	--hd-top-height: 50px;

	@include breakpoint(large) {
		--hd-height: 75px;
	}

	// Foundation
	// grid-container 內容區左右 Padding
	--pd-x: #{rem-calc(15)};
	--pd-x-global: #{rem-calc(15)}; // 避免被內層的數值影響，保存Global

	@include breakpoint(medium) {
		--pd-x: #{rem-calc(50)};
		--pd-x-global: #{rem-calc(50)}; // 避免被內層的數值影響，保存Global
	}

	@include breakpoint(xlarge) {
		--pd-x: #{rem-calc(120)};
		--pd-x-global: #{rem-calc(120)}; // 避免被內層的數值影響，保存Global
	}
}
