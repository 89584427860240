.btn-box {
	margin-top: rem-calc(40);

	@include breakpoint(medium) {
		margin-top: rem-calc(60);
	}
}

.button {
	@include breakpoint(small only) {
		padding: em-calc(8 / 14) em-calc(14 / 14);
		font-size: rem-calc(14);
	}

	&.tiny {
		--pd-x: #{(18 / 12 * 1em)};
		--pd-y: #{(9 / 12 * 1em)};

		padding: var(--pd-y) var(--pd-x);
	}

	&.dark {
		color: $dark-color;

		&:hover,
		&:focus {
			color: $primary-light-1;
		}
	}

	&.hollow {
		&.dark {
			border-color: $dark-color;

			&:hover,
			&:focus {
				background: $primary-light-1;
				color: $white;
				border-color: $white;
			}
		}
	}

	&.shadow {
		box-shadow: $primary-shadow;
	}
}

// 線框按鈕
.button-border {
	background-color: transparent;
	color: #000;
	border: 1px solid currentColor;
	border-radius: 99px;
}

// 加入喜歡按鈕
.btn-like {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	line-height: 1;
	font-size: rem-calc(40);
	color: $white;
	cursor: pointer;

	&.dark {
		color: $red;
	}

	i {
		&::before {
			margin: 0;
		}
	}

	.solid {
		opacity: 0;
		pointer-events: none;
	}

	.be-icon-heart-solid {
		color: $red;
	}

	.be-icon-heart-light {
		transform: translate(-20%, 6%);
	}

	&.is-active {
		color: $white;

		.be-icon-heart {
			position: relative;
			opacity: 0;
			transition: opacity 0.3s 1s;
		}

		.solid {
			opacity: 1;
			animation: heart-beat 1s;
		}
	}

	@include breakpoint(medium) {
		font-size: rem-calc(48);
	}

	@include breakpoint-hover {
		transition: color 0.3s;

		&:active {
			transform: scale(0.8);
		}

		&:hover,
		&:focus {
			color: $pink;
		}
	}
}

@keyframes heart-beat {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	30% {
		opacity: 1;
		transform: scale(0.3);
	}

	60% {
		transform: scale(1.5);
	}

	100% {
		// opacity: 0;
		transform: scale(1);
	}
}
