.tag {
	position: absolute;
	top: 24px;
	right: 0;
	z-index: 1;
	display: block;
	padding-left: rem-calc(10);
	min-width: rem-calc(120);
	font-size: rem-calc(12);
	line-height: rem-calc(30);
	background: $pink;
	color: $white;
	border-radius: 15px 0px 0px 15px;
}
