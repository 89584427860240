/* 中文襯線字 */
// 從 HTML 載入 Google Font 設定
.font-serif {
	font-family: 'Noto Serif TC', serif;
}

// 草寫英文
.font-script {
	font-family: 'Dancing Script', cursive;
}
