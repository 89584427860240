// icon 的圓圈
.icon-box {
	--size: #{rem-calc(60)};

	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--size);
	height: var(--size);
	font-size: var(--font-size, calc(var(--size) * 0.7));
	color: var(--icon-color, $white);
	background: var(--icon-bg, $primary-color);
	border: 1px solid var(--icon-border-color, transparent);
	border-radius: 999px;

	i {
		pointer-events: none;

		&::before {
			margin: 0;
		}
	}
}
