// https://animate.style/
@keyframes bounce {

	from,
	20%,
	53%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, 0, 0);
	}

	40%,
	43% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -30px, 0) scaleY(1.1);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -15px, 0) scaleY(1.05);
	}

	80% {
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, 0, 0) scaleY(0.95);
	}

	90% {
		transform: translate3d(0, -4px, 0) scaleY(1.02);
	}
}

@keyframes swing {
	20% {
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		transform: rotate3d(0, 0, 1, -5deg);
	}

	to {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@keyframes rubberBand {
	from {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		transform: scale3d(0.95, 1.05, 1);
	}

	75% {
		transform: scale3d(1.05, 0.95, 1);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}

// -----------------------------------------------------------------
// Custom
// 波浪
@keyframes wave {
	0% {
		opacity: 1;
		transform: scale(0);
	}

	33.33%,
	100% {
		opacity: 0;
		transform: scale(2);
	}
}

// 漂浮
@keyframes float {
	0% {
		transform: translate3d(var(--ani-from-x, 0), var(--ani-from-y, 0), 0);
	}

	50% {
		transform: translate3d(var(--ani-to-x, 0), var(--ani-to-y, 0), 0);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
