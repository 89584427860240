// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：Layout樣式 Loader、Header、Footer、Cookie

// -------------------------------------------------------

/* Loader */
.loader {
	$root: &;

	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: $gray-300;
	background: rgba($white, 0.5);
	transition: opacity 0.5s;

	&__cont {
		--size: #{rem-calc(100)};
		--mg: calc(var(--size) / -2);

		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: var(--mg);
		margin-left: var(--mg);
		width: var(--size);
		height: var(--size);
		background-color: $white;
		border-radius: 100%;
		transition: transform 1s, opacity 1s;

		&::before {
			--circle-size: calc(var(--size) * 1.2);
			--mg: calc(var(--circle-size) / -2);
			--border-color: currentColor;

			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: var(--mg);
			margin-left: var(--mg);
			width: var(--circle-size);
			height: var(--circle-size);
			border: 2px solid;
			border-color: var(--border-color) transparent var(--border-color) transparent;
			border-radius: 50%;
			transition: border-color 0.5s;
			animation: rotate 1.2s linear infinite;
		}
	}

	&__img-box {
		width: calc(var(--size) * 0.8);

		&::before {
			@include before-img-size(140, 110, false);
		}
	}

	&__img {
		animation: shake-body 1s infinite alternate linear;
		transform-origin: bottom center;
	}

	&__text {
		font-size: rem-calc(12);
	}

	&.is-loaded {
		color: $primary-color;
		opacity: 0;
		transition-delay: 1.5s;

		#{$root} {
			&__cont {
				transition-delay: 0.5s;
				transform: scale(1.5);
				opacity: 0;
			}

			&__img-box {
				animation: bounce 0.5s;
			}
		}
	}

	@include breakpoint(medium) {
		&__cont {
			--size: #{rem-calc(150)};
		}
	}
}

@keyframes shake-body {
	0% {
		transform: rotate(10deg);
	}

	100% {
		transform: rotate(-10deg);
	}
}


// -------------------------------------------------------

// Logo
.brand-logo {
	position: relative;
	display: block;
	width: rem-calc(100);

	&::before {
		@include before-img-size(146, 40);
	}

	@include breakpoint(medium) {
		width: rem-calc(146);
	}
}

// -------------------------------------------------------

/* Header */
// Header Mobile 版本寫在所有項目後方
.hd {
	position: fixed;
	z-index: 900;
	top: 0;
	left: 0;
	width: 100%;

	&.is-collapse {
		@include breakpoint(medium down) {
			.hd-main--mo {
				background-color: $white;
				border-color: transparent;
				box-shadow: $primary-shadow;
			}
		}

	}

	@include breakpoint(large) {
		transition: transform 0.5s;

		&.is-collapse {
			transform: translateY(-50px);
			box-shadow: $primary-shadow;

			.hd-top,
			.hd-main--both {
				background-color: $white;
			}

			.hd-main__r {
				// 因為會影響子選單顯示，所以不能用transform，會影響顯示效能，不用transition
				padding-right: #{rem-calc(36 + 20)}; // 圓形按鈕的大小 + 間距
			}

			.mem-menu__list {
				--size: #{rem-calc(36)};
				--adjust-right: #{rem-calc(20)};

				position: absolute;
				z-index: 100;
				top: calc(100% - 75px / 2 - var(--size) / 2);
				right: calc(var(--pd-x-global) - #{rem-calc(15)} - var(--adjust-right));
				transform: translateX(-100%);

				@include breakpoint(1920) {
					--adjust-right: 0;
				}

				&>li {
					&>a {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0;
						width: var(--size);
						height: var(--size);
						white-space: nowrap;
						background: $primary-color;
						color: $white;
						border-color: transparent;

						i {
							margin-right: 0;
						}

						&::after,
						.name {
							display: none;
						}
					}
				}
			}
		}
	}
}

// 子選單打開
.menu-sub-open {

	@include breakpoint(large) {

		.hd-top,
		.hd-main {
			background-color: $white;
		}
	}
}

// Top + Main Menu Wrapper
.hd-menu {
	@include breakpoint(large) {
		// 覆蓋掉原本OffCanvas樣式
		position: static;
		visibility: inherit !important;
		overflow: inherit;
		width: auto;
		height: auto !important;
		background: transparent;
		box-shadow: none !important;
		transform: none;
	}
}

// Header Top
.hd-top {
	&__l {
		ul {
			margin-left: 0;
			margin-bottom: 0;
		}
	}

	&__r {
		.button {
			margin-bottom: 0;
		}
	}

	@include breakpoint(large) {
		border-bottom: 1px solid $gray-50;
		transition: background-color 0.3s;

		&__cont {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: rem-calc(3); // 不明原因所有物件偏下，所以要調整回來
			height: var(--hd-top-height);
		}

		&__l,
		&__r {
			display: flex;
			align-items: center;
		}
	}
}

// 語言選單
.menu-lang {
	&.dropdown.menu {

		// 調整icon大小
		i {
			font-size: rem-calc(16);
		}
	}

	.selector-clone {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: 5px;
			bottom: 0;
			width: calc(100% - 10px);
			height: 1px;
			background: $white;
		}
	}
}

// Top Menu: 加入狸樂聚, 廠商登入
.hd-top-menu {
	a {
		display: block;
	}

	@include breakpoint(large) {
		a {
			color: $gray-600;
		}

		&__item {
			display: inline-block;
		}

		&__link {
			display: inline-block;
			vertical-align: middle;
			padding: 0.85em 1em;
			border-radius: 99px;
			transition: background-color .25s ease-out, color .25s ease-out;
			font-size: rem-calc(12);
			line-height: 1;
			text-align: center;
			cursor: pointer;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}
}

// 會員選單(PC) + 浮動按鈕(Mobile)
.mem-menu {
	&__btn-cta {
		display: inline-block;
		padding: em-calc(9.002 / 14);
		font-size: rem-calc(14);
		text-align: center;
		background: $primary-color;
		color: $white;
		border-radius: 99px;

		&:focus {
			color: $white;
		}
	}

	@include breakpoint(medium down) {
		&__btn-cta {
			min-width: 170px;
		}
	}

	@include breakpoint(large) {
		margin-left: rem-calc(8);

		&__list {
			margin: 0;
		}

		&__btn-cta {
			display: flex;
			align-items: center;
			padding: 0.1em 0;
			font-size: rem-calc(12);
			background: transparent;
			color: $black;
			border: 1px solid currentColor;

			.be-icon {
				font-size: em-calc(20 / 12);
			}

			[class*="be-icon-arrow-"] {
				font-size: em-calc(15 / 12);
			}
		}
	}
}


// --- Header Main ---
// Header Main
.hd-main {
	transition: background-color 0.3s;

	&__r {
		display: flex;
		align-items: center;

		.btn-box {
			margin-top: 0;
		}
	}

	@include breakpoint(large) {
		&__cont {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

// Header Main Menu
.main-menu {
	$el: &;

	ul {
		list-style: none;
		margin: 0;
	}

	a {
		display: block;
	}

	&__sub {
		&__img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: (14 / 20 * 1em);
			color: $black;
		}

		&__title {
			color: $primary-color;
		}

		&__img-list {
			text-align: center;
		}
	}

	@include breakpoint(large) {
		display: flex;
		align-items: center;

		a {
			color: $gray-400;
		}

		&__list {
			display: flex;
			list-style: none;
		}

		&__item {

			&:hover,
			&:focus-within {
				#{$el} {
					&__link {
						&::before {
							transform: scale(1);
						}
					}

					&__sub {
						max-height: 500px;
						transition-delay: 0s;
					}
				}
			}
		}

		// 第一層
		&__link {
			--mg-x: #{rem-calc(10)};

			position: relative;
			margin: 0 var(--mg-x);
			height: 75px;
			justify-content: center;
			align-items: center;

			@include bem-tag {
				display: flex;
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 5px;
				background: $primary-color;
				transform: scaleX(0);
			}
		}

		// 第二層
		&__sub {
			$sub: &;

			--px: #{rem-calc(20)};
			--py: #{rem-calc(50)};

			position: absolute;
			top: 100%;
			left: 0;
			display: flex;
			justify-content: center;
			overflow: hidden;
			width: 100%;
			max-height: 0;
			text-align: center;
			background: rgba($white, 0.98);
			box-shadow: 0 2px 4px rgba($black, 0.08) inset;
			transition: max-height 0.5s 0.1s;

			&__img-list {
				padding: var(--py) var(--px);

				#{$sub} {
					&__item {
						display: inline-block;
					}

					&__link {
						padding: rem-calc(20);
						font-size: rem-calc(20);
					}

					&__img {
						width: 95px;
					}
				}
			}

			&__group {
				--mg-x: #{rem-calc(40)};

				display: inline-block;
				margin: 0 var(--mg-x);
				padding: var(--py) var(--px);
			}

			&__title {
				margin-bottom: em-calc(6 / 20);
				font-size: em-calc(20 / 16);
				text-align: left;
				color: $primary-color;
			}

			&__group-list {
				text-align: left;

				#{$sub} {
					&__link {
						padding: rem-calc(8) 0;
					}
				}
			}
		}
	}

	@include breakpoint-hover {
		a {

			&:hover,
			&:focus {
				color: $primary;

				#{$el} {
					&__sub {
						&__img {
							color: inherit;
						}
					}
				}
			}
		}

		&__link {
			&::before {
				transition: transform 0.3s;
			}

			&:hover,
			&:focus {
				&::before {
					transform: scaleX(1);
				}
			}
		}
	}
}

// Search
.hd-search {
	$root: &;

	--pos: 3px;
	--input-height: 36px;
	--input-pl: #{rem-calc(36)};
	--input-bg: #{rgba(#FAE7D6, 0.2)};

	&__input-box {
		position: relative;
		display: flex;
		align-items: center;
	}

	&__input {
		margin-bottom: 0;
		height: var(--input-height);
		background-color: transparent;
		border-color: transparent;
		border-radius: 99px;
		box-shadow: none;

		&:focus {
			box-shadow: none;
		}
	}

	&__btn-submit {
		--pd-y: #{em-calc(4 / 16)};
		--pd-x: 10px;

		margin-bottom: 0;
		padding: var(--pd-y) var(--pd-x);
		height: calc(var(--input-height) - var(--pos) * 2);
		white-space: nowrap;
	}

	.be-icon {
		position: absolute;
		top: 50%;
		left: var(--pos);
		font-size: rem-calc(24);
		transform: translateY(-50%);
	}

	@include breakpoint(small only) {
		&__btn-submit {
			font-size: rem-calc(14);
		}
	}

	@include breakpoint(medium down) {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: rem-calc(10);
		padding-left: rem-calc(14);
		width: calc(100% - var(--hd-height));
		height: var(--hd-height);
		background: $linear-light-yellow;

		&__cont {
			width: 100%;
		}

		&__input {
			padding-left: rem-calc(40);

			&,
			&:focus {
				background: transparent;
				border: 0 none;
			}
		}
	}

	@include breakpoint(large) {
		position: relative;
		margin-left: rem-calc(6);
		padding-left: rem-calc(18);

		&::before {
			content: '';
			position: absolute;
			top: calc(50% - 10px);
			left: 0;
			width: 1px;
			height: 20px;
			background-color: #BEBEBE;
		}

		&__input-box {
			border: 1px solid rgba($gray-300, 0.2);
			border-radius: 99px;

			&,
			&:focus-within {
				background-color: var(--input-bg);
			}

			&:focus-within {
				border-color: $primary-color;
			}

			&:not(:focus-within):not(.has-value) {
				#{$root} {
					&__btn-submit {
						pointer-events: none;
					}
				}
			}
		}

		&__input {
			width: 140px;
			padding-left: var(--input-pl);
			transition: all 0.3s;

			&,
			&:hover,
			&:focus {
				background-color: transparent;
				border-color: transparent;
				box-shadow: none;
			}
		}

		&__btn-submit {
			margin-right: var(--pos);
		}

		&:not(:hover):not(:focus-within) {
			padding-left: rem-calc(14 - 6);

			#{$root} {
				&__input-box {
					background-color: transparent;
					border-color: transparent;
				}

				&__input {
					--input-pl: #{rem-calc(8)};

					width: 24px;
					color: transparent;
					background-color: transparent;

					@include placeholder(transparent);
				}

				&__btn-submit {
					background-color: transparent;
					color: $black;
				}
			}
		}
	}
}

.hd-btn-quote {
	padding: em-calc(9 / 16) em-calc(18 / 16);

	@include breakpoint(medium down) {
		font-size: rem-calc(12);
		background: transparent;
		color: $primary;
		border: 1px solid $primary;
		box-shadow: none !important;
	}

	@include breakpoint(large) {
		margin-left: rem-calc(5);
	}
}

// Hamburger Menu
.hd-ham {
	$root: &;

	position: relative;
	z-index: 999;
	display: block;
	cursor: pointer;
	width: var(--hd-height);

	@include breakpoint(large) {
		display: none;
	}

	&::before {
		@include before-img-size(1, 1, true);
	}

	&__svg {
		--size: 30px;

		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		margin-top: calc(var(--size) / 2 * -1);
		margin-left: calc(var(--size) / 2 * -1);
		width: var(--size);
		pointer-events: none;
		transform: rotate(180deg);
	}

	&__g {
		transition: transform 1s;
	}

	&__path {
		stroke-dasharray: 20 (53 - 20);
		stroke-dashoffset: 53;
		transform-origin: 15px 15px;
		transition-duration: 1s;
		transition-property: stroke-dasharray, stroke-dashoffset;

		&.line-c {
			stroke-dasharray: 9 (40 - 9);
			stroke-dashoffset: 20;
		}
	}

	// Open
	&.is-menu-open {
		background-color: $primary-light-1;

		#{$root} {
			&__g {
				transform: translateX(-30px);
			}
		}

		.line-t,
		.line-b {
			stroke-dashoffset: 20;
		}

		.line-c {
			stroke-dashoffset: 10;
			transition-duration: 1.5s;
		}
	}
}

// === Mobile Menu ===

// Mobile 顯示的使用者名稱
.menu-mem-info {
	.main-menu__link {
		--pd-y: #{rem-calc(24)};
	}

	&__name {
		font-size: rem-calc(20);
	}
}

// 選單子選項還有下層項目
// 因為不能放置於其他 mixin 內(@include)，只能搬到外層
@mixin menu-has-sub($el) {
	&.has-sub {
		#{$el} {
			@extend %menu-has-sub--sign;
		}

		&.is-open {
			#{$el} {
				@extend %menu-has-sub--open;
			}
		}
	}
}

@include breakpoint(medium down) {

	// 建立選單收起共用樣式
	@at-root %toggle-menu-common--disable {
		@include toggle-menu-item--disable;
	}

	@at-root %toggle-menu-common--enabled {
		@include toggle-menu-item--enabled;
	}

	// 選單A連結樣式
	@at-root %menu-link {
		padding: var(--menu-pd-y) 0;
		font-size: rem-calc(16);
		font-weight: 500;
		color: $black;
	}

	// 選單含有子選單樣式 - 符號
	@at-root %menu-has-sub--sign {
		position: relative;
		display: block;

		// (+)
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 18px;
			height: 2px;
			background: $black;
		}

		&::after {
			transform: rotate(90deg);
			transition: transform 0.3s;
		}
	}

	// 選單含有子選單樣式 - 開啟
	@at-root %menu-has-sub--open {
		&::after {
			transform: rotate(180deg);
		}
	}

	@at-root %menu-sub-item--text {
		&:last-child a {
			border: 0 none; // 避免影響收起來的物件樣式(toggle-menu-item)，所以不用:not(:last-child)寫
		}

		a {
			padding: rem-calc(10);
			display: flex;
			align-items: center;
			font-size: rem-calc(14);
			border-bottom: var(--menu-border-sub);
		}

		.be-icon {
			margin-left: rem-calc(-6);
			font-size: em-calc(30 / 14); // icon-size / font-size
			line-height: 1;
		}
	}

	// ===================

	// Top + Main Menu Wrapper
	.hd-menu {
		--menu-border: 1px solid #{rgba($black, 0.4)};
		--menu-border-sub: 1px solid #E9E9E9;
		--menu-pd-y: #{rem-calc(14)};
		--menu-cont-pl: #{rem-calc(30)};

		display: flex;
		flex-direction: column;
		padding-top: var(--hd-height);
		height: 100vh;
		height: 100svh;
	}

	// Header Top
	.hd-top {
		position: relative;
		order: 2;
		padding-top: rem-calc(16);
		background-color: #FBFBFC;
	}

	// 語言選單
	.dropdown.menu.menu-lang {
		>li {
			@include menu-has-sub('>a');

			// 收起後的會影響的物件
			@include toggle-menu-item('.is-open', '.menu', '%toggle-menu-common');
			@include toggle-menu-item('.is-open', '.menu a', '%toggle-menu-common');

			&>a {
				@extend %menu-link;

				border: 0 none;
			}
		}

		.menu {
			padding-left: var(--menu-cont-pl);
			border-top: var(--menu-border);
			border-radius: 0;
			box-shadow: none;

			.selector-clone {
				display: none;
			}

			li {
				@extend %menu-sub-item--text;
			}
		}
	}

	// Top Menu: 加入狸樂聚, 廠商登入
	.hd-top-menu {
		&__link {
			@extend %menu-link;

			border-top: var(--menu-border);
		}
	}

	// 會員選單(PC) + 浮動按鈕(Mobile)
	.mem-menu {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
	}

	// Header Main
	.hd-main {
		$el: &;

		&--both {
			flex: 1;
			order: 1;
		}

		&--mo {
			border-bottom: 1px solid $gray-50;

			#{$el} {
				&__cont {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-right: 0;
				}
			}
		}
	}

	// Header Main Menu
	.main-menu {
		$el: &;

		margin-bottom: rem-calc(100);
		width: 100%;

		&__item {
			@include menu-has-sub(#{$el}__link);

			border-bottom: var(--menu-border);

			// 收起後的會影響的物件
			@include toggle-menu-item('.is-open', '.main-menu__sub', '%toggle-menu-common');
			@include toggle-menu-item('.is-open', '.main-menu__sub__item', '%toggle-menu-common');
			@include toggle-menu-item('.is-open', '.main-menu__sub__link', '%toggle-menu-common');
			@include toggle-menu-item('.is-open', '.main-menu__sub__title', '%toggle-menu-common');
			@include toggle-menu-item('.is-open', '.main-menu__sub__text-list', '%toggle-menu-common');
			@include toggle-menu-item('.is-open', '.main-menu__sub__img-list', '%toggle-menu-common');
			@include toggle-menu-item('.is-open', '.main-menu__sub__group', '%toggle-menu-common');
		}

		// 第一層
		&__link {
			@extend %menu-link;
		}

		// 第二層
		&__sub {
			$sub: &;

			padding-left: var(--menu-cont-pl);
			border-top: var(--menu-border);

			@at-root %menu-sub-common {
				padding: rem-calc(15) 0;
			}

			&__link {
				color: $gray-600;
				font-size: rem-calc(14);
			}

			&__text-list {
				padding: rem-calc(5) 0;

				#{$sub} {
					&__item {
						@extend %menu-sub-item--text;
					}
				}
			}

			&__img-list {
				@extend %menu-sub-common;

				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));

				@include bem-tag {
					margin-bottom: rem-calc(-12);
				}

				#{$sub} {
					&__item {
						margin-bottom: rem-calc(12);
					}

					&__img {
						--size: #{em-calc(60 / 14)}; // img-size / font-size

						width: var(--size);
						height: var(--size);
						color: $gray-400;
					}
				}
			}

			&__group {
				@extend %menu-sub-common;

				&:not(:last-child) {
					// margin-bottom: rem-calc(13);
					padding-bottom: rem-calc(5);
					border-bottom: var(--menu-border-sub);
				}
			}

			&__title {
				margin-bottom: em-calc(5 / 12);
				font-size: rem-calc(12);
			}

			&__group-list {
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

				#{$sub} {

					&__link {
						padding: 8px 0;
					}
				}
			}
		}
	}
}

@include breakpoint(medium only) {

	// 選單打開
	.is-off-canvas-open {
		.main-wrapper {
			filter: blur(5px);
		}
	}

	// Header Main
	.hd-menu {
		.grid-container {
			--pd-x: #{rem-calc(15)};
		}
	}
}

// --------------------------------------------------------

/* Footer */
// Footer
.ft {
	position: relative;

	@include breakpoint(small only) {
		padding-top: 57%;
		background-repeat: no-repeat;
		background-position: bottom right 27%, top center;
		background-size: 936px, 100%;

		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			bottom: 0;
			width: 100%;
			height: calc(100% - (971 / 375) * 100vw + 3px); // 100%高 - 背景圖片比例(高/寬) * 100螢幕寬(vw) + 保險銜接距離 5px
			// background: linear-gradient(to bottom, #fda43a, #ff8a21); // 延伸淺色版本
			background: linear-gradient(to bottom, #ff8c2a, #fd6f25); // 延伸深色版本
		}
	}

	@include breakpoint(medium) {
		padding-top: rem-calc(659);
		background: no-repeat bottom right / 1920px;
	}

	@include breakpoint(medium only) {
		margin-top: rem-calc(-120);
		padding-top: 43%;
		background-size: 2150px;
		background-position-x: 65%;
	}

	@include breakpoint(large only) {
		margin-top: rem-calc(-50);
	}

	@include breakpoint(xlarge) {
		margin-top: rem-calc(-200);
	}

	@include breakpoint(1921) {
		background: repeat-x bottom center / 3016px;
	}

	// Lazy Load Bg
	&.entered {
		@include breakpoint(small only) {
			background-image: url('../images/footer-bg-s-2.png'), url('../images/footer-bg-s-1.png');
		}

		@include breakpoint(medium) {
			background-image: url('../images/footer-bg.png');
		}

		@include breakpoint(1921) {
			background-image: url('../images/footer-bg-xl.png');
		}

	}
}

.ft-float-circle {
	z-index: -1;
	right: 6%;
	top: -5%;

	@include breakpoint(small only) {
		display: none;
	}
}

// 右下快速服務
.fast-ser {
	$root: &;

	--img-size: 55px; // 圖片大小

	position: absolute;
	z-index: 30;
	bottom: var(--bottom, 105px);
	right: 8px;

	@at-root %tri {
		transition: transform 0.5s;
	}

	@include breakpoint(small only) {
		&.is-fixed {
			position: fixed;
			right: 8px;
			bottom: 30px;
		}
	}

	@include breakpoint(medium) {
		right: 20px;
		position: fixed;
		bottom: 30px;

		&__btn-swtich {
			width: 108px;
		}
	}

	&__btn-switch {
		$img-w: 107;
		$img-h: 141;

		position: relative;
		z-index: 1;
		width: var(--img-size);
		cursor: pointer;

		&::before {
			@include before-img-size(107, 141);
		}

		// 吉祥物
		&,
		.img-text {
			@extend %tri;

			transform-origin: bottom center;
			transition-timing-function: cubic-bezier(.82, -0.59, .2, 1.58);
		}

		.img-ip {
			width: percentage(107 / $img-w);
			height: percentage(125 / $img-h);
		}

		// 「點我」文字
		.img-text {
			top: percentage(68 / $img-h);
			left: percentage(12 / $img-w);
			width: percentage(96 / $img-w);
			height: percentage(73 / $img-h);
		}

		@include breakpoint(large) {
			--img-size: 108px; // 圖片大小
		}

		@include breakpoint-hover {

			&:hover,
			&:focus {
				.img-ip {
					animation: swing 1s infinite;
				}
			}
		}
	}

	&__list {
		@extend %tri;

		--panel-size: calc(var(--img-size) * 2.8);

		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: calc(var(--panel-size) / -2);
		margin-left: calc(var(--panel-size) / -2);
		width: var(--panel-size);
		height: var(--panel-size);
		list-style: none;
		// border: 1px solid #ccc; // 測試對齊用
		// border-radius: 50%; // 測試對齊用
		transition: transform 0.5s;

		@include breakpoint(medium) {
			--panel-size: calc(var(--img-size) * 3.5);

			top: 63%;
		}
	}

	&__item {
		@extend %tri;

		--r: 0deg;
		--item-size: #{rem-calc(45)}; // 圓形按鈕大小

		position: absolute;
		z-index: 5;
		top: 50%;
		left: 0;
		margin-top: calc(var(--item-size) / -2);
		margin-left: calc(var(--item-size) / -2);
		width: var(--item-size);
		height: var(--item-size);

		&:nth-child(2) {
			--pos: 14.14%;

			top: var(--pos);
			left: var(--pos);
		}

		&:nth-child(3) {
			top: 0;
			left: 50%
		}

		// Close
		&:nth-child(4) {
			--pos: 14.14%;
			--panel-size-close: calc(var(--item-size) * #{(28 / 54)});

			top: 25%;
			left: 77%;
			width: var(--panel-size-close);
			height: var(--panel-size-close);

			#{$root} {
				&__link {
					font-size: calc(var(--panel-size-close) * #{(37 / 54)});
					border: 1px solid $white;
					cursor: pointer;

					@include breakpoint(medium) {
						border-width: 2px;
					}
				}
			}
		}

		@for $i from 1 through 4 {
			&:nth-child(#{$i}) {
				transition-delay: $i * 0.1s;
			}
		}

		@include breakpoint(medium) {
			--item-size: #{rem-calc(54)};

			&:nth-child(4) {
				top: 39%;
				left: 73%;
			}
		}
	}

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: calc(var(--item-size) * #{(37 / 54)});
		background: $pink;
		color: $white;
		border-radius: 99px;

		@include breakpoint-hover {

			&:hover,
			&:focus {
				background: $primary-color;
				color: $white;
			}
		}
	}

	&__checkbox {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;

		&:not(:checked) {
			~#{$root} {
				&__list {
					transform: scale(0) rotate(-180deg);
				}

				&__btn-switch {
					@include breakpoint(small only) {
						.img-ip {
							animation: rubberBand 0.5s;
						}
					}
				}
			}

			~#{$root}__list #{$root}__item {
				transform: scale(0);
			}
		}

		&:checked {
			~#{$root} {
				&__btn-switch {
					.img-text {
						transform: scale(0);
					}

					@include breakpoint(small only) {
						.img-ip {
							animation: bounce 0.5s;
						}
					}

					@include breakpoint(medium) {
						transform: scale(#{(68 / 107)}) translateY(10%);
					}
				}
			}
		}
	}
}

// Go Top
.go-top {
	--size: #{rem-calc(45)};

	position: absolute;
	z-index: 20;
	right: 23px;
	top: 14px;
	margin-top: calc(var(--size) / -2);
	width: var(--size);
	height: var(--size);
	background-color: $primary-color;
	border-radius: 100%;

	&__text {
		position: absolute;
		left: 50%;
		top: -5px;
		font-size: rem-calc(12);
		transform: translate(-50%, -100%);
	}

	.be-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: calc(var(--size) * #{(42 / 45)});
		color: $white;
		pointer-events: none;
		transform: translate(-50%, -50%);
	}

	.be-icon-click-top-2_circle {
		opacity: 0.4;
	}

	@include breakpoint(medium only) {
		top: 70px;
	}

	@include breakpoint(large) {
		top: 30px;
	}

	@include breakpoint(1921) {
		right: calc((100% - 1920px) / 2 + 53px);
	}

	@include breakpoint-hover {

		&::before,
		&::after {
			content: '';
			position: absolute;
			z-index: 5;
			left: 0;
			top: -20%;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			border: 1px solid rgba($black, 0.5);
			box-shadow: 0 0 4px rgba($black, 0.08);
			transform: scale(0);
		}

		&:hover,
		&:focus {
			animation: swing 3s infinite;

			&::before,
			&::after {
				animation: wave 3s infinite;
			}

			&::after {
				animation-delay: 0.3s;
			}
		}
	}
}

// Footern主要內容
.ft-main {
	font-size: rem-calc(14);
	color: $white;

	a {
		color: inherit;
	}
}

// Footer Top
.ft-top {
	@include breakpoint(small only) {
		margin-bottom: rem-calc(60);
	}

	@include breakpoint(medium only) {
		margin-left: auto;
		margin-right: auto;
		max-width: 650px;
	}

	@include breakpoint(large) {
		margin-bottom: rem-calc(90);
		display: flex;
		justify-content: space-between;

		&__l,
		&__r {
			flex: 1;
		}
	}
}

%ft-title {
	font-size: rem-calc(16);
	font-weight: 700;
	margin-bottom: em-calc(25 / 18);

	@include breakpoint(large) {
		font-size: rem-calc(18);
	}
}

// Follow US
.ft-follow-us {
	@include breakpoint(small only) {
		margin-bottom: rem-calc(25);
	}

	@include breakpoint(medium only) {
		margin-bottom: rem-calc(50);
	}

	&__title {
		@extend %ft-title;
	}

	&__sns {
		margin-bottom: rem-calc(10);
		text-align: left;
		line-height: 1;

		&__item {
			display: inline-block;

			&:not(:last-child) {
				margin-right: rem-calc(15);
			}
		}

		&__link {
			display: block;
			font-size: rem-calc(55);

			.be-icon {
				&::before {
					margin: 0;
					display: block;
				}
			}

			@include breakpoint($xs-down) {
				font-size: rem-calc(40);
			}

			@include breakpoint-hover {
				position: relative;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 100%;
					border: 1px solid transparent;
					box-sizing: border-box;
					transition: transform 0.5s, border-color 0.5s;
				}

				&:hover,
				&:focus {
					&::before {
						border-color: $white;
						transform: scale(1.2);
						box-shadow: $primary-shadow;
					}
				}
			}
		}

		@include breakpoint($xs-down) {
			&__link {
				font-size: rem-calc(40);
			}
		}
	}

	&__info {
		line-height: 1.8;
	}
}

// Footer sitemap
.ft-sitemap {
	$root: &;

	&__title {
		@extend %ft-title;
	}

	a {
		display: block;
	}

	@include breakpoint(small only) {
		--menu-border: 1px solid #{rgba($white, 0.4)};
		--menu-pd-y: #{rem-calc(14)};
		--menu-cont-pl: #{rem-calc(30)};

		margin-bottom: rem-calc(50);

		// 建立Sitemap選單收起共用樣式
		@at-root %toggle-ft-sitemap-common--disable {
			@include toggle-menu-item--disable;
		}

		@at-root %toggle-ft-sitemap-common--enabled {
			@include toggle-menu-item--enabled;
		}

		&__item {
			// 收起後的會影響的物件
			@include toggle-menu-item('.is-open', '.ft-sitemap__sub__link', '%toggle-ft-sitemap-common');
			@include toggle-menu-item('.is-open', '.ft-sitemap__sub', '%toggle-ft-sitemap-common');

			&:last-child {
				#{$root} {
					&__title {
						border-bottom: var(--menu-border);
					}
				}
			}

			&.is-open {
				#{$root} {
					&__title {
						border-bottom: transparent;

						&::after {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		// 第一層
		&__title {
			position: relative;
			border-top: var(--menu-border);
			position: relative;
			display: block;
			margin-bottom: 0;
			padding: var(--menu-pd-y) 0;
			font-size: 1rem;
			font-weight: 500;
			cursor: pointer;

			// (+)
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				width: 18px;
				height: 2px;
				background: currentColor;
			}

			&::after {
				transform: rotate(90deg);
				transition: transform 0.3s;
			}
		}

		&__sub {
			padding-left: var(--menu-cont-pl);
			border-top: var(--menu-border);

			&__link {
				padding: rem-calc(10);
				display: flex;
				align-items: center;
				font-size: rem-calc(14);
				border-bottom: var(--menu-border-sub);
			}
		}
	}

	@include breakpoint(medium) {
		display: flex;
		justify-content: space-between;
	}

	@include breakpoint(medium only) {
		margin-bottom: rem-calc(50);
	}

	@include breakpoint(large) {
		&__sub {
			--pd: 0.2em;
			--pd-reverse: calc(var(--pd) * -1);

			margin-top: var(--pd-reverse);
			margin-left: var(--pd-reverse);

			&__link {
				padding: var(--pd);
			}
		}
	}

	@include breakpoint-hover {
		&__sub {
			&__link {

				&:hover,
				&:focus {
					color: $black;
				}
			}
		}
	}
}

// Other Link + Logo
.ft-bottom {
	display: flex;

	@include breakpoint(medium down) {
		flex-direction: column-reverse;
	}

	@include breakpoint(large) {
		align-items: end;
		justify-content: space-between;
	}

	// 會受Fixed住的快速功能影響而調整
	@include breakpoint-between(large, 1199) {
		&__r {
			margin-right: rem-calc(120);
		}
	}

	@include breakpoint-between(1200, 1720) {
		&__r {
			margin-right: rem-calc(80);
		}
	}
}

// Footer左下其他連結
.ft-other-link {
	font-size: 0; // 為了把<li>display:inline-block間隙移除

	a {
		display: inline-block;
	}

	&__item {
		display: inline-block;
		font-size: rem-calc(14);

		&:not(:last-child) {
			margin-right: 0.5em;

			&::after {
				content: '';
				display: inline-block;
				height: 1em;
				width: 1px;
				margin-left: 0.5em;
				background-color: currentColor;
				transform: translateY((1.6em - 1) / 4);
			}
		}
	}

	&__link {
		padding: 0.5em 0.2em;

		@include breakpoint-hover {

			&:hover,
			&:focus {
				color: $black;
			}
		}
	}

	@include breakpoint(medium down) {
		text-align: center;
	}

	@include breakpoint(small only) {
		margin-top: rem-calc(25);
		margin-bottom: rem-calc(15);

		&__item {
			font-size: rem-calc(12);
		}
	}

	@include breakpoint(large) {
		margin-left: -0.5em;
	}
}

.ft-bottom__r {
	@include breakpoint(medium) {
		margin-bottom: rem-calc(-20);
	}
}

// Logo
.ft-logo {
	margin: 0 auto rem-calc(4);
	width: rem-calc(150);

	@include breakpoint(medium) {
		width: rem-calc(200);
	}
}

// Slogan
.ft-slogan {
	color: $white;

	@include breakpoint(small only) {
		margin-bottom: rem-calc(18);
		font-size: rem-calc(12);
		text-align: center;
		letter-spacing: 2px;
	}

	@include breakpoint(medium only) {
		margin-left: auto;
		margin-right: auto;
		width: 200px;
	}

	@include breakpoint(medium) {
		overflow: hidden;
		text-align: justify;
		font-size: rem-calc(16);

		// 使用 text-align: justify; 要加這個項目
		&::after {
			content: "";
			display: inline-block;
			width: 100%;
		}
	}
}

// Copyright
.ft-copyright {
	padding-bottom: rem-calc(25);
	font-size: rem-calc(12);
	color: $white;

	@include breakpoint(large) {
		margin-top: rem-calc(50);
	}
}

// -------------------------------------------------------

/* Cookie box */
.notification-box {
	$root: &;

	position: fixed;
	z-index: 1000;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: rem-calc(12);
	background-color: $white;
	box-shadow: 0 0 10px rgba($black, 0.2);
	animation: cookieToShow 1s;

	&__cont {
		--pd-y: #{rem-calc(16)};

		position: relative;
		display: flex;
		align-items: center;
		padding-top: var(--pd-y);
		padding-bottom: var(--pd-y);
		color: $black;

	}

	&__text-box {
		margin-right: rem-calc(15);

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: $black;
			text-decoration: underline;
		}
	}

	&.is-hide {
		display: none;
	}

	&.to-hide {
		animation: cookieToHide 0.5s forwards;
	}

	.button {
		margin-bottom: 0;
		min-width: rem-calc(74);
	}

	@include breakpoint(large) {
		&__cont {
			justify-content: center;
		}

		&__text-box {
			margin-right: rem-calc(20);

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@include breakpoint-hover {
		&__text-box {
			a {

				&:hover,
				&:focus {
					color: $primary-color;
				}
			}
		}

		.button {

			&:hover,
			&:focus {
				background: $primary-color;
				color: $white;
				border-color: $primary-color;
			}
		}
	}

	@keyframes cookieToShow {
		0% {
			transform: translateY(100%);
		}
	}

	@keyframes cookieToHide {
		100% {
			transform: translateY(100%);
		}
	}
}

// END Cookie box
