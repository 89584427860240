// Form / Input / Select / Textarea

%input-global {
	border-color: $gray-300;
	box-shadow: none;

	&:focus {
		box-shadow: none;
	}

	@include breakpoint-hover {

		&:hover,
		&:focus {
			border-color: $primary-color;
		}
	}
}

%input-height {
	height: rem-calc(45);
}

#{text-inputs()} {
	@extend %input-global;
	@extend %input-height;
}

input {
	&[type='date'] {

		// 隱藏預設右側月曆Icon
		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none;
		}

		@include breakpoint-hover {
			cursor: pointer;
		}
	}

	// UAT245: 修正 iOS 15 後日期欄位會置中問題
	// Ref: https://simplernerd.com/js-align-text-left-ios-date-input/
	&[type='date'],
	&::-webkit-date-and-time-value {
		text-align: left;
		text-align: -webkit-left;
	}
}

select {
	@extend %input-global;
	@extend %input-height;

	@include select-style; // 寫於 _variable_mixin_function
}

textarea {
	@extend %input-global;

	@include custom-scroll-bar(#d4d3d1, $white);

	min-width: 100%;
	min-height: rem-calc(220);
	resize: vertical;

	@include breakpoint(large) {
		min-height: rem-calc(220);
	}
}

label {
	cursor: pointer;
	font-size: inherit;
}
