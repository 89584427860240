// 裝飾
// Border+Radius漸層，參考：https://dev.to/afif/border-with-gradient-and-radius-387f
.float-circle {
	--size: 80px;
	--border-width: 3px;
	--ani-to-y: 30px; // Animation

	position: absolute;
	padding: var(--border-width); // border soze
	width: var(--size);
	height: var(--size);
	background: $linear-light-yellow;
	border-radius: 100%;
	-webkit-mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
	animation: float 5s infinite alternate;

	@include breakpoint(medium) {
		--size: 150px;
	}

	@include breakpoint(large) {
		--size: 250px;
	}
}
