// 水平 Padding (Medium+ 避免左右寬度過窄)
.grid-container {
	// --pd-x: #{rem-calc(16)}; // 設定於:root
	padding-right: var(--pd-x);
	padding-left: var(--pd-x);

	// 比較寬的內容(Header, Index Banner)
	&.large-cont {
		// 螢幕寬 - 左右距離 + large padding-x (有改過預設值)
		max-width: rem-calc(1920 - 125 * 2 + 100 * 2);
	}
}

// 區塊內容
.sec {
	--sec-pd-y: #{rem-calc(50)};

	position: relative;
	padding-top: var(--sec-pd-y);
	padding-bottom: var(--sec-pd-y);

	@include breakpoint(medium) {
		--sec-pd-y: #{rem-calc(100)};
	}
}

// 區塊標題
.sec-title {
	$root: &;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: rem-calc(30);
	text-align: center;

	&__title-box {
		position: relative;
		display: inline-block;
	}

	&__title {
		color: $black;
		margin-bottom: em-calc(18 / 48);
	}

	&__deco {
		position: absolute;
		left: 100%;
		bottom: 65%;
		font-size: rem-calc(24);
		color: $primary-color;
		transform: rotate(-15deg);
		transform-origin: left bottom;
	}

	&__desc {
		font-weight: 300;
		color: $gray-300;
	}

	&.text-left {
		text-align: left;
	}

	&.js-ani {
		--delay: 0s;

		#{$root} {
			&__title {
				span {
					display: inline-block;
					transform: rotateY(90deg);
					transition: transform 0.5s;

					@for $i from 1 through 20 {
						&:nth-child(#{$i}) {
							transition-delay: calc(var(--delay) + 0.05s * #{$i});
						}
					}
				}
			}

			&__deco {
				clip-path: polygon(0 0, 0% 0%, 0% 100%, 0% 100%);
				transition: clip-path 0.5s calc(var(--delay) + 0.25s);
			}

			&__desc {
				opacity: 0;
				transition: opacity 0.5s calc(var(--delay) + 0.5s);
			}
		}
	}

	&.is-animated {
		#{$root} {
			&__title {
				span {
					transform: rotateY(0);
				}
			}

			&__deco {
				clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
			}

			&__desc {
				opacity: 1;
			}
		}
	}

	@include breakpoint(medium) {
		margin-bottom: rem-calc(40);

		&__deco {
			font-size: rem-calc(32);
		}
	}
}
